/**
 * 404 page
 */
/* Import section */
import { Link } from "gatsby"
import React from "react"
import Herotype4 from "../components/herotype4"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo
        title="404: Not found"
        description="Hello Health USA - 404: Not found"
      />

      {/* Hero section */}
      <section className="section-hero-static top-section">
        <Herotype4 h1title="404: Not Found"/>
      </section>

      {/*Static page details section */}
      <section className="section-details-static">
        <div className="wrap-maincontent-static">
          <div className="maincontent-static post-content">
          <p>Oops! The page you are looking for cannot be found or doesn’t exist.</p>
          <p>Not to worry, you can make your way back to our <Link to="/">homepage</Link> or check out the menu at the top of the page to find what you are looking for. </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
